import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';

export const ToastSuccess = (props) => (
    <div className="toast toast-success">
        <div>{props.children}</div>
    </div>
);

export const ToastError = ({ text = '未知错误\n请稍后重试' }) => (
    <div className="toast toast-error">
        <div>
            {text.split(/\\n|\n|<br\/>|<br \/>/).join('，')}
        </div>
    </div>
);

const GlobalToast = (props) => {

    if (!props.type) {
        return null;
    }

    return createPortal(
        <Fragment>
            {props.type === 'report' && <ToastSuccess>举报成功</ToastSuccess>}

            {props.type === 'ontop' && <ToastSuccess>置顶成功</ToastSuccess>}
            {props.type === 'offtop' && <ToastSuccess>取消置顶成功</ToastSuccess>}

            {props.type === 'onlock' && <ToastSuccess>禁止回复成功</ToastSuccess>}
            {props.type === 'offlock' && <ToastSuccess>允许回复成功</ToastSuccess>}

            {props.type === 'onhide' && <ToastSuccess>隐藏成功</ToastSuccess>}
            {props.type === 'offhide' && <ToastSuccess>取消隐藏成功</ToastSuccess>}

            {props.type === 'onblock' && <ToastSuccess>加入黑名单成功</ToastSuccess>}
            {props.type === 'offblock' && <ToastSuccess>移出黑名单成功</ToastSuccess>}

            {props.type === 'delete' && <ToastSuccess>删除成功</ToastSuccess>}

            {props.type === 'commentSuccess' && <ToastSuccess>评论成功</ToastSuccess>}
            {props.type === 'replySuccess' && <ToastSuccess>回复成功</ToastSuccess>}

            {props.type === 'ongood' && <ToastSuccess>标记好问题成功</ToastSuccess>}
            {props.type === 'offgood' && <ToastSuccess>取消好问题成功</ToastSuccess>}

            {props.type instanceof Error && <ToastError text={props.type.message} />}
        </Fragment>
        , document.body);
};

export default GlobalToast;
