import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

/**
 * React 中的 Daemon
 */
export class Daemon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentError: null
        };

        this.onError = this.onError.bind(this);
        this.onUnHandledRejection = this.onUnHandledRejection.bind(this);
        this.catchError = this.catchError.bind(this);
    }

    componentDidMount() {
        window.addEventListener('error', this.onError, false);
        window.addEventListener('unhandledrejection', this.onUnHandledRejection, false);
    }

    onError(error) {
        this.showError(error);
    }

    /**
     * Promise Reject 的兜底处理
     * @param event
     */
    onUnHandledRejection(event) {

        const error = event.reason;
        const isErrorObject = error instanceof Error;

        // 只处理 Error 对象
        if (!isErrorObject) return;

        this.showError(error);
    }

    /**
     * 显示异常
     */
    onErrorRenderPortal() {

        const { props, state } = this;

        if (!state.currentError) {
            return null;
        }

        // 获取异常提示的界面
        const ErrorPortal = props.onErrorRenderPortal(state.currentError);

        return createPortal(ErrorPortal, document.body);

    }

    /**
     * 是否原生的异常
     */
    isNativeError(error) {
        const nativeError = [
            'EvalError',        // 与 eval() 有关的错误
            'InternalError',    // JS 引擎内部错误，例如递归太多
            'RangeError',       // 数值变量或参数超出其有效范围
            'ReferenceError',   // 无效的引用
            'SyntaxError',      // eval() 在解析代码过程中发生语法错误
            'TypeError',        // 变量或参数不属于有效类型
            'URIError'          // encodeURI() 或 decodeURI() 的参数无效
        ];

        const regex = new RegExp(nativeError.join('|'));

        return regex.test(error.name);
    }

    /**
     * 显示异常内容
     * @param error
     * @param DO_NOT_AUTO_HIDE
     */
    showError(error, DO_NOT_AUTO_HIDE = false) {

        const { props } = this;

        // 忽略原生的异常
        if (props.ignoreNativeError && this.isNativeError(error)) {
            return;
        }

        this.setState({ currentError: error });

        if (!DO_NOT_AUTO_HIDE) {
            setTimeout(() => this.setState({ currentError: null }), props.timeout);
        }
    }

    /**
     * 用来捕获异常
     * window.onerror 在跨域的时候，会触发浏览器安全策略，只输出 Script error. 而不输出错误详情
     * @todo 这是一个临时方案，一旦用这种方案，导致 catchError 需要层层传递
     * @param error
     */
    catchError(error) {
        this.showError(error);
    }

    render() {

        const { state, props } = this;

        return (
            <Fragment>

                {typeof props.children === 'function'
                    ? props.children(this.catchError)
                    : props.children
                }

                {this.onErrorRenderPortal()}
            </Fragment>
        );

    }
}


Daemon.defaultProps = {
    ignoreNativeError: true,
    timeout: 3000,
    onErrorRenderPortal: function () {
        console.log('未传递 onErrorRenderPortal 的处理函数');
    }
};

Daemon.propTypes = {
    /**
     * 忽略 JS 内置的错误类型
     */
    ignoreNativeError: PropTypes.bool,
    /**
     * 弹窗超时时间，默认为 3000 毫秒
     */
    timeout: PropTypes.number,
    /**
     * 出错时候的提示界面处理函数
     * @param {Error} error 错误对象
     * @return component 返回一个 JSX 或者 React Component
     */
    onErrorRenderPortal: PropTypes.func.isRequired
};
