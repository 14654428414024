import React, { useState } from 'react';
import { ChevronDownIcon } from 'tdesign-icons-react';
import { Avatar } from 'components/user';
import { CommentOperator } from 'components/comment-item';
import { BtnLike, BtnReply } from 'components/btn-icon';
import { UserEnter, BtnPublic } from 'components/user-enter';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { href_profile as hrefProfile, ceGroupName } from 'components/href-helper';
import { ImageAlbum } from '@tencent/coral-design';
import { ImageDesktop } from 'components/comment-image';
import formatTime from 'components/util/formatTime';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.less';

const cls = 'post-comment-list';

const PostCommentList = (props) => {
  const [imageArr, setImageArr] = useState(null);
  const [showImageAlbum, setShowImageAlbum] = useState(null);

  // 选中显示图片
  const handleImagesClick = (i, imageArr) => {
    setShowImageAlbum(i);
    setImageArr(imageArr);
  };

  const isRenderLink = isAdmin => props.renderLink === 'on' || isAdmin;

  // 返回显示标签
  const getTag = (isAdmin, isAuthorId, isMine) => {
    if (isAdmin) {
      return <span className={`${cls}__admin`}>官方</span>;
    }
    if (isAuthorId) {
      return <span className={`${cls}__author`}>作者</span>;
    }
    if (isMine) {
      return <span className={`${cls}__mine`}>我的</span>;
    }
    return null;
  };
  // 返回显示部门
  const getGroup = (isGroupName, isGroupDetailName) => (isGroupName || isGroupDetailName) && (
    <span className={`${cls}__group`}>{`${isGroupName ? `${isGroupName}/` : ''}${isGroupDetailName}`}</span>
  );
  return (
    <div className={cls}>
      {props.comments.map((item, index) => {
        const { fromUser } = item;
        const isDisabled = Boolean(props.post.is_locked);
        const isShow = Boolean(props.showReplyTextarea === item.id);
        return (
          <div key={index} className={`${cls}__list`}>
            <div className={`${cls}__side`}>
              <a href={hrefProfile(fromUser.id)} target="_blank" className={`${cls}__avatar-wrap`} rel="noreferrer">
                <Avatar src={fromUser.avatarUrl} />
              </a>
            </div>
            <div className={`${cls}__main ${cls}__border`}>
              <div className={`${cls}__user`}>
                <a className={`${cls}__avatar`} href={hrefProfile(fromUser.id)} target="_blank" rel="noreferrer">
                  <span className={classNames({ 'post-comment-list__name-admin': fromUser.isAdmin })}>
                    {fromUser.userName}
                  </span>
                </a>
                {getTag(fromUser.isAdmin, fromUser.id === props.authorId, props.user.user_id === fromUser.id)}
                {getGroup(fromUser.groupName, fromUser.groupDetailName)}
                <span className={`${cls}__time`}>{formatTime(item.createdTimestamp)}</span>
              </div>
              <div className={`${cls}__cont`}>
                <span
                  className={`${cls}__html`}
                  dangerouslySetInnerHTML={dangerouslySetInnerHTML(item.content, props.productId, 'p', isRenderLink(fromUser.isAdmin))}
                ></span>
              </div>
              <div className={`${cls}__img`}>
                <ImageDesktop
                  imgList={item.images}
                  handleImagesClick={imgIndex => handleImagesClick(imgIndex, item.images)}
                />
              </div>
              <div className={`${cls}__operator`}>
                <CommentOperator
                  is_admin={props.user.is_admin}
                  is_admin_post={item.fromUser.isAdmin}
                  is_owner={Boolean(Number(props.user.user_id) === Number(item.fromUser.id))}
                  is_hidden={item.isHidden}
                  is_spam={props.post.is_spam}
                  is_reply={true}
                  onCheckLogin={() => props.onCheckLogin()}
                  onClickMenu={res => props.onClickMenu(res, item.id)}
                  onClickDelete={() => props.onClickDelete(item.id)}
                />
                <BtnLike
                  isLiked={item.hasLiked}
                  like_count={item.likeCount}
                  onClick={() => props.likeReply(item.id, item.hasLiked)}
                />
                <BtnReply
                  is_disabled={isDisabled}
                  is_show={isShow}
                  onClick={() => props.handleShowTextarea(isShow, isDisabled, item.id)}
                />
                {isShow && (
                  <div>
                    <UserEnter
                      onSubmit={(value, images) => props.onSubmit(value, images, item.id)}
                      placeholder={`回复 ${item.fromUser.userName}`}
                      textbtn="回复"
                      onUploadImage={(data, onProgress) => props.onUploadImage(data, onProgress)}
                      onError={(error) => {
                        props.catchError(error);
                      }}
                      External={
                        item.fromUser.isAdmin && props.post.is_hidden ? (
                          <div>
                            <BtnPublic
                              checked={props.checkedDisabledHidden}
                              onChange={() => props.toggleDisabledHidden}
                            />
                          </div>
                        ) : null
                      }
                    />
                  </div>
                )}
              </div>
              {item.replies.map((i, k) => {
                const { fromUser, toUser } = i;
                const isShow = Boolean(props.showReplyTextarea === i.id);
                return (
                  <div key={k} className={`${cls}__list`}>
                    <div className={`${cls}__side`}>
                      <a
                        href={hrefProfile(fromUser.id)}
                        target="_blank"
                        className={`${cls}__avatar-wrap`}
                        rel="noreferrer"
                      >
                        <Avatar src={fromUser.avatarUrl} />
                      </a>
                    </div>
                    <div className={`${cls}__main`}>
                      <div className={`${cls}__user`}>
                        <a
                          className={`${cls}__avatar`}
                          href={hrefProfile(fromUser.id)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className={classNames({ 'post-comment-list__name-admin': fromUser.isAdmin })}>
                            {fromUser.userName}
                          </span>
                        </a>
                        {getTag(fromUser.isAdmin, fromUser.id === props.authorId, props.user.user_id === fromUser.id)}
                        {getGroup(fromUser.groupName, fromUser.groupDetailName)}
                        <span className={`${cls}__time`}>{formatTime(i.createdTimestamp)}</span>
                      </div>
                      <div className={`${cls}__cont`}>
                        {i.rootCommentID !== i.replyID && toUser.userName && (
                          <span>
                            回复&nbsp;&nbsp;
                            <a href={hrefProfile(toUser.id)} target="_blank" rel="noreferrer">
                              <span className={classNames({ 'post-comment-list__name-admin': fromUser.isAdmin })}>
                                {toUser.userName}
                              </span>
                            </a>
                            {getTag(toUser.isAdmin, toUser.id === props.authorId, props.user.user_id === fromUser.id)}
                            &nbsp;:
                          </span>
                        )}
                        <span
                          className={`${cls}__html`}
                          dangerouslySetInnerHTML={dangerouslySetInnerHTML(i.content, props.productId, 'p', isRenderLink(toUser.isAdmin))}
                        ></span>
                      </div>
                      <div className={`${cls}__img`}>
                        <ImageDesktop
                          imgList={i.images}
                          handleImagesClick={imgIndex => handleImagesClick(imgIndex, i.images)}
                        />
                      </div>
                      <div className={`${cls}__operator`}>
                        <CommentOperator
                          is_admin={props.user.is_admin}
                          is_admin_post={i.fromUser.isAdmin}
                          is_owner={Boolean(Number(props.user.user_id) === Number(i.fromUser.id))}
                          is_hidden={i.isHidden}
                          is_spam={props.post.is_spam}
                          is_reply={true}
                          onCheckLogin={() => props.onCheckLogin()}
                          onClickMenu={res => props.onClickMenu(res, i.id, item.id)}
                          onClickDelete={() => props.onClickDelete(i.id, item.id)}
                        />
                        <BtnLike
                          isLiked={i.hasLiked}
                          like_count={i.likeCount}
                          onClick={() => props.likeReply(i.id, i.hasLiked, item.id)}
                        />
                        <BtnReply
                          is_disabled={isDisabled}
                          is_show={isShow}
                          onClick={() => props.handleShowTextarea(isShow, isDisabled, i.id)}
                        />
                        {isShow && (
                          <div className={`${cls}__child-userenter`}>
                            <UserEnter
                              onSubmit={(value, images) => props.onSubmit(value, images, i.id)}
                              placeholder={`回复 ${i.fromUser.userName}`}
                              textbtn="回复"
                              onUploadImage={(data, onProgress) => props.onUploadImage(data, onProgress)}
                              onError={(error) => {
                                props.catchError(error);
                              }}
                              External={
                                i.fromUser.isAdmin && props.post.is_hidden ? (
                                  <div>
                                    <BtnPublic
                                      checked={props.checkedDisabledHidden}
                                      onChange={() => props.toggleDisabledHidden}
                                    />
                                  </div>
                                ) : null
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {item.replies.length !== item.replyCount && (
                <div
                  className={`${cls}__replyCount`}
                  tabIndex={0}
                  role="button"
                  onClick={(replyId) => {
                    props.handleReplyCount(item.id);
                  }}
                  onKeyPress={(replyId) => {
                    props.handleReplyCount(item.id);
                  }}
                >
                  展开{item.replyCount - item.replies.length}条回复
                  <ChevronDownIcon size="16" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {showImageAlbum !== null && (
        <ImageAlbum
          imageList={imageArr.map(img => img.original_url)}
          previewList={imageArr.map(img => img.thumb_url)}
          showPreview={true}
          showIndex={showImageAlbum}
          onClose={event => setShowImageAlbum(null)}
        />
      )}
    </div>
  );
};

PostCommentList.propTypes = {
  comments: PropTypes.array,
  post: PropTypes.object,
  user: PropTypes.object,
  authorId: PropTypes.number,
  checkedDisabledHidden: PropTypes.bool,
  showReplyTextarea: PropTypes.bool,
  postId: PropTypes.string,
  productId: PropTypes.string,
  likeReply: PropTypes.func,
  onCheckLogin: PropTypes.func,
  onClickMenu: PropTypes.func,
  onClickDelete: PropTypes.func,
  handleShowTextarea: PropTypes.func,
  toggleDisabledHidden: PropTypes.func,
  catchError: PropTypes.func,
  onUploadImage: PropTypes.func,
  onSubmit: PropTypes.func,
  handleReplyCount: PropTypes.func,
  renderLink: PropTypes.string,
};

export { PostCommentList };
