// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */

import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ResponseBase, RequestBase } from 'components/api/types';

interface PostBaseReqBase extends RequestBase {
  /** 项目ID */
  productId: number | string;
  /** 话题ID */
  postId: number | string;
}

interface DeleteApiV2PostTopicsReq extends PostBaseReqBase {
  topicId: number
}
interface DeleteApiV2PostTopicsResp extends ResponseBase<[]> { }


interface PostApiV2PostTopicsReq extends PostBaseReqBase {
  topicId: number
}
interface PostApiV2PostTopicsResp extends ResponseBase<[]> { }


// 删除反馈绑定话题
export const deleteApiV2PostTopics: ApiRequest<
  DeleteApiV2PostTopicsReq,
  DeleteApiV2PostTopicsResp
> = ({ productId, postId, topicId }) => deleteJson({
  url: `/api/v3/${productId}/post/${postId}/roadmap/topics/${topicId}`,
});
// 为反馈绑定话题
export const postApiV2PostTopics: ApiRequest<
  PostApiV2PostTopicsReq,
  PostApiV2PostTopicsResp
> = ({ productId, postId, topicId }) => postJSON({
  url: `/api/v3/${productId}/post/${postId}/roadmap/topics/${topicId}`,
});
