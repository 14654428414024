import React, { Component } from 'react';
import _, { flattenDeep, unionBy } from 'lodash-es';
import BasePagePc from 'components/ui/layout/base-page-pc';
import Sentry from 'components/sentry';
import { StickyTool } from '@tencent/coral-design';
import { message, Modal as ConfirmModal } from '@tencent/ten-design-react';
import { PostCommentList } from './common/comment-list';
import '@tencent/ten-design-react/dist/ten.css';
import {
  get_api_posts as getApiPosts,
  getApiV3PostsReplies,
  getApiV3PostsRepliesReplies,
  getApiV3PostsRepliesById,
  get_api_tip as getApiTip,
  get_api_profile_detail as getApiProfileDetail,
  post_api_liked as postApiLiked,
  post_api_posts_replies as postApiPostsReplies,
  post_api_tip_batch as postApiTipBatch,
  post_api_v2_posts as postApiV2Posts,
  post_api_posts_liked as postApiPostsLiked,
  post_api_v2_posts_replies as postApiV2PostsReplies,
  post_api_posts_replies_liked as postApiPostsRepliesLiked,
  post_api_abuse_reports as postApiAbuseReports,
  post_api_posts_upload_images as postApiPostsUploadImages,
  delete_api_posts_replies as deleteApiPostsReplies,
  delete_api_posts as deleteApiPosts,
  getApiNotificationSetting,
  getApiNotificationSubscribeStatus,
  post_api_authorize_callback as postApiAuthorizeCallback,
  apiGetAuthorizedApp,
  getProductsTagsList,
  postListTagsAllGet,
  getApiV3RoadmapSettingStatus,
} from 'components/api';
import { QQ_PRODUCT_ID, QQ_ANDROID_PRODUCT_ID, QQ_IOS_PRODUCT_ID } from 'components/constants/app';
import { getProductId, getContentId, verificationCodePromise } from 'components/util';

import { BreadCrumbs } from 'components/breadcrumbs';
import { PoweredBy } from 'components/poweredby';
import { ModalReport } from 'components/secure-report';
import { Modal } from 'components/modal';
import { UserEnter, BtnPublic } from 'components/user-enter';
import {  Avatar } from 'components/user';
import { DesktopTippedModal } from 'components/tipped';
import { ErrorBoundary } from 'components/error-boundary';
import { OaPopUps } from 'components/oa-pop-ups';
import { LoadMore } from 'components/load-more';
import { Daemon } from 'components/daemon';
import { href_home as hrefHome, href_profile as hrefProfile } from 'components/href-helper';
import { GoodQuestionPopup } from 'components/post-label';
import { PostPopupWxSubscripe } from 'components/post-pop-ups';
import { METHOD } from 'components/notification/constants';
import { FeedDetail } from 'components/feed-detail';
import { AuthorMessage } from './common/author-message';
import GlobalToast from './common/toast';
import { TapdConfirm } from 'components/tapd-confirm';
import 'components/css/pc/reset.less';
import './style.less';
import { PLATFORM_ID } from 'components/constants/tapd';
import { hasTapdPlatform } from 'components/tapd-confirm/util';
import { fromEntries } from 'components/util/new-attribute';
import PropTypes from 'prop-types';

class App extends Component {
  static getUrlParams() {
    const [productId, postId] = location.pathname.match(/(\d+)\/post\/(\d+)/);
    return { productId, postId };
  }

  /**
   * 返回上一页，或者打开产品首页
   * */
  static navigatorBack() {
    if (history.length > 0) {
      location.href = hrefHome();
    } else {
      const res = location.pathname.match(/(.*)post\/\d+/);
      location.href = `${res[1]}${location.search}`;
    }
  }

  constructor(props) {
    super(props);

    this.productId = getProductId();
    this.postId = getContentId();
    this.wxTimer = null;
    this.isWxTimeing = false;
    this.subscribeLimiteTimes = 0; // 微信订阅次数，上限为30
    this.onSubmit = this.onSubmit.bind(this);
    this.handleShowTextarea = this.handleShowTextarea.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.showToast = this.showToast.bind(this);
    this.onTippedSuccess = this.onTippedSuccess.bind(this);
    this.toggleDisabledHidden = this.toggleDisabledHidden.bind(this);
    this.handlepostPopupSuccessRetry = this.handlepostPopupSuccessRetry.bind(this);
    this.authorizedInfo = { apps: {}, platforms: [] };

    this.state = {
      // 登录信息
      accountInfo: {},
      // 产品信息
      productData: {},
      // 用户信息
      user: {
        user_id: null,
        avatar: null,
        nickname: null,
        is_admin: false,
      },
      // 帖子信息
      post: {
        tags: [],
      },
      // 评论信息
      comments: [],
      // 评论信息的分页数据
      pagination: {
        has_more_pages: true,
        next_page_id: null,
      },
      // 是否支持打赏
      can_be_tipped: false,
      // 已打赏的名单
      tip_batch: [],
      // 已点赞的清单
      liked_list: {},
      // 是否显示举报的弹窗
      show_modal_report: false,
      // 是否显示登录弹窗
      show_modal_login: false,
      // 是否显示打赏弹窗
      show_modal_tipped: false,
      // 全局提示
      toast: '',
      // 提问者信息
      author_info: {},
      // 错误信息
      error_info: false,
      // 当前显示哪个评论的输入框
      show_reply_textarea: false,
      // 回复后会关闭隐藏的状态
      checked_disabled_hidden: this.initStateForCheckedDisabledHidden(),
      // 展示首次标记好问题提示
      show_modal_good_question: false,
      // 展示微信扫码订阅弹窗
      showSuccessPostPopup: false,
      // 微信一次性订阅url
      successPostUrl: null,
      // 微信扫码订阅弹窗的状态
      successPostStatus: 'pending',
      // 展示tapd弹窗
      showTapdConfirm: false,
      // 关联平台数据loading状态
      mattersLoading: false,
      // 关联平台数据
      authMatters: [],
      // 全部标签
      tagArrList: [],
      // 话题设置开关
      showTopic: true,
      // 更多评论页码
      countPagination: {},
      // PC消息通知渠道
      methodPc: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  init() {
    const { productId, postId } = this;

    // 拉取帖子信息
    getApiPosts({ productId, postId })
      .then(async (resp) => {
        await this.postListTagsAllGetApi(resp.data);
        return resp;
      })
      .then((resp) => {
        this.setState({ post: resp.data });
        return resp.data;
      })
      .then(post => getApiProfileDetail({ productId, userId: post.user_id }))
      .then(resp => this.setState({ author_info: resp.data }))
      .catch((error) => {
        this.showToast(error);

        if (error.message === '找不到贴子') {
          this.setState({ error_info: ErrorBoundary.ERROR_TYPES.NOT_FOUND });
        }

        if (error.message === '没有权限') {
          this.setState({ error_info: ErrorBoundary.ERROR_TYPES.FORBIDDEN });
        }
      });

    // 判断是否支持打赏
    getApiTip({ productId, postId }).then(resp => this.setState({ can_be_tipped: resp.data.can_be_tipped }));

    // 获取赞赏的人数和次数
    postApiTipBatch({ productId, postId }).then(resp => this.setState({ tip_batch: resp.data[postId].data }));

    this.getPlatformData().then(() => this.handleGetAuthMatters());

    // 获取产品的话题设置
    getApiV3RoadmapSettingStatus({ productId })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ showTopic: res.data?.status === 'on' });
        }
      });
  }

  // 获取标签选中信息
  postListTagsAllGetApi(resData) {
    return new Promise((resolve) => {
      postListTagsAllGet(this.productId, 'post', [resData.id])
        .then((contentTagList) => {
          if (!_.isEmpty(contentTagList.data)) {
            getProductsTagsList(this.productId).then((tagList) => {
              const tagArrList = tagList.data.map((item, index) => ({
                ...item,
                isSet: false,
              }));
              this.setState({ tagArrList });
              const tagsMap = fromEntries(tagList.data.map(tag => [tag.id, tag]));
              for (const item of Object.values(contentTagList.data)) {
                item.tags = Object.values(item.tags).map(id => tagsMap[id]);
              }
              resData.tags = contentTagList.data[resData.id].tags;
              resolve(resData);
            });
          } else {
            getProductsTagsList(this.productId).then((tagList) => {
              const tagArrList = tagList.data.map((item, index) => ({
                ...item,
                isSet: false,
              }));
              this.setState({ tagArrList });
              resolve(resData);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          resolve(resData);
        });
    });
  }

  // 回复并取消隐藏的初始值 默认为tru。
  initStateForCheckedDisabledHidden() {
    // NOTE: QQ产品希望回复时默认不取消隐藏，所以整些hard code
    const isQQ = [QQ_PRODUCT_ID, QQ_ANDROID_PRODUCT_ID, QQ_IOS_PRODUCT_ID].includes(Number(this.productId));
    if (isQQ) {
      return false;
    }
    // 默认值为 true
    let value = true;
    const res = JSON.parse(localStorage.getItem('checked_disabled_hidden'));

    if (typeof res !== 'undefined' && res !== null) {
      value = res;
    }

    return value;
  }

  verifyCaptcha() {
    const { user, productData } = this.state;
    if (!user || !productData) {
      return {};
    }

    const {
      is_system: isSystem,
      is_admin: isAdmin,
    } = user;
    const {
      is_slider_captcha_enabled: isSliderCaptchaEnabled,
    } = productData;

    if (
      isSystem || (!isAdmin && isSliderCaptchaEnabled)
    ) {
      return verificationCodePromise('captcha_post')
        .then(({ randstr, ticket }) => ({
          ...this.subParams,
          captcha_randstr: randstr,
          captcha_ticket: ticket,
        }));
    }

    return {};
  }

  /**
   * 提交评论或回复，并更新评论列表
   * @param value             评论的文字内容
   * @param images            图片
   * @param [replyId]      回复的评论ID
   * */
  onSubmit(value, images, replyId) {
    const { state, props, productId, postId } = this;

    // 如果用户未登录，拉起登录弹窗
    if (!this.checkAndShowModalLogin()) {
      return Promise.reject(new Error('用户未登录'));
    }

    const params = {
      productId,
      postId,
      text: value,
      images,
      parent_reply_id: replyId,
    };

    // 回复评论成功的提示反馈/更新评论
    const promiseFeedBack = async (resp) => {
      const { comments, pagination } = this.state;
      const { latest_reply_id } = resp.data;

      getApiV3PostsRepliesById({
        productId,
        postId,
        commentId: resp.data.latest_reply_id,
      }).then((res) => {
        if (res.data.rootCommentID !== '0') {
          const commentArr = comments.map(comment => (comment.id === res.data.rootCommentID
            ? {
              ...comment,
              replies: unionBy([res.data], comment.replies, 'id'),
              replyCount: comment.replyCount + 1,
            }
            : comment));
          this.setState({
            comments: commentArr,
            show_reply_textarea: false,
          });
        } else {
          this.setState({
            comments: unionBy([res.data], comments, 'id'),
            show_reply_textarea: false,
            pagination: Object.assign({}, pagination, {
              total: pagination.total + 1,
            }),
          });
        }
      });
      await this.judgeWhetherToSubscribe(latest_reply_id);
      this.showToast(replyId ? 'replySuccess' : 'commentSuccess');
    };

    // 管理员，帖子被隐藏了，管理员又选择了回复关闭隐藏，则帮管理员公开帖子
    const promisePublicPost = (resolved) => {
      if (!state.user.is_admin || !state.post.is_hidden || !state.checked_disabled_hidden) {
        return resolved;
      }

      return postApiV2Posts({
        productId,
        postId,
        opt: 'hide',
        val: 'off',
        scene: 'post',
      }).then((resp) => {
        this.setState({ post: resp.data });
      });
    };

    return Promise.resolve()
      .then(() => this.verifyCaptcha())
      .then((res) => postApiPostsReplies(Object.assign(res, params)))
      .then(promiseFeedBack)
      .then(promisePublicPost)
      .catch(error => this.showToast(error));
  }

  /**
   * 判断是否给用户展示订阅消息
   * @param {String} objId 评论或反馈的id
   */
  async judgeWhetherToSubscribe(objId) {
    if (this.state.user.is_admin) return;

    try {
      const overviewRes = await getApiNotificationSetting({
        productId: this.productId,
      });

      if (overviewRes.status !== 0) throw new Error(overviewRes.message);

      const { scene, method } = overviewRes.data;

      this.setState({ methodPc: method.pc });

      if (!scene.find(item => item === 'replied_reply_by_admin')) return;

      const channel = method.pc;
      if (!channel) return;

      if (channel === METHOD.wx_subscribe) {
        const successPostUrl = `${location.origin}/embed/${this.productId}/subscribe/${objId}?scene=replied_reply_by_admin`;
        this.checkSubscripteStatus(objId, successPostUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * 轮询确认订阅状态
   * @param {String} objId 评论或反馈的id
   * @param {String} successPostUrl 微信订阅url
   */
  checkSubscripteStatus(objId, successPostUrl) {
    this.isWxTimeing = true;
    this.setState({
      successPostStatus: 'pending',
      showSuccessPostPopup: true,
      successPostUrl,
    });
    this.statusParam = {
      scene: 'replied_reply_by_admin',
      object_type: 'reply',
      object_id: objId,
    };

    this.subscribeLimiteTimes = 0;
    this.getSubscripteStatus();
  }

  getSubscripteStatus() {
    this.wxTimer = setTimeout(async () => {
      if (!this.isWxTimeing) return;

      try {
        if ((this.subscribeLimiteTimes += 1) > 40) {
          throw new Error('subscribeLimiteTimes');
        }

        const res = await getApiNotificationSubscribeStatus(this.productId, this.statusParam);

        if (res.status !== 0) throw res;

        if (res.data.status === 'subscribed') {
          this.setState({
            successPostStatus: 'subscribed',
          });

          setTimeout(() => {
            this.setState({
              showSuccessPostPopup: false,
            });
          }, 2000);
          return;
        }

        this.getSubscripteStatus();
      } catch (error) {
        this.setState({
          successPostStatus: 'error',
        });
        this.subscribeLimiteTimes = 0;
      }
    }, 1000);
  }

  handlepostPopupSuccessRetry() {
    this.setState({ successPostStatus: 'pending' });
    this.getSubscripteStatus();
  }

  // 关闭微信弹窗
  onCloseWxPopup() {
    this.setState({ showSuccessPostPopup: false });
    clearTimeout(this.wxTimer);
    this.isWxTimeing = false;
  }

  /**
   * 加载评论和是否点赞
   */
  getComments() {
    const { state, productId, postId } = this;

    getApiV3PostsReplies({
      productId,
      postId,
      nextPageId: state.pagination.next_page_id,
    })
      .then((resp) => {
        this.setState(preState => ({
          pagination: { ...preState.pagination, ...resp.pagination },
          comments: [...preState.comments, ...resp.data],
        }));
        return resp;
      })
      .then(resp => postApiLiked({
        productId,
        object_list: [postId, ...Object.keys(resp.data)],
      }))
      .then(resp => this.setState(preState => ({
        liked_list: Object.assign({}, preState.liked_list, resp.data),
      })))
      .catch(error => this.showToast(error));
  }

  /**
   * 给主帖点赞或取消点赞
   * */
  likePost() {
    const { productId, postId, state } = this;

    if (!this.checkAndShowModalLogin()) {
      return;
    }

    const preValue = Boolean(state.liked_list[postId] && state.liked_list[postId].liked);
    postApiPostsLiked({
      productId,
      postId,
      val: preValue ? 'off' : 'on',
    }).then((resp) => {
      // 更新点赞数量
      this.setState(preState => ({
        liked_list: Object.assign({}, preState.liked_list, {
          [postId]: { liked: !preValue },
        }),
        post: Object.assign({}, preState.post, {
          like_count: resp.data.like_count,
          like_list: resp.data.like_list,
        }),
      }));
    });
  }

  /**
   * 给评论点赞或取消点赞
   * @param replyId       评论的ID
   * @param hasLiked      是否已经点赞
   * @param replyParentId  子集ID
   * */
  likeReply(replyId, hasLiked, replyParentId) {
    const { productId, postId, state } = this;
    let val = '';
    if (!this.checkAndShowModalLogin()) {
      return;
    }
    if (hasLiked) {
      val = 'off';
    } else {
      val = 'on';
    }
    postApiPostsRepliesLiked({
      productId,
      postId,
      replyId,
      val,
    }).then((resp) => {
      // 更新点赞数量
      const likeCount = resp.data.like_count;
      const targetParentArr = state.comments.map((obj) => {
        if (obj.id === replyParentId) {
          return {
            ...obj,
            replies: obj.replies.map((objs) => {
              if (objs.id === replyId) {
                return { ...objs, likeCount, hasLiked: !hasLiked };
              }
              return { ...objs };
            }),
          };
        }
        return { ...obj };
      });
      const targetArr = state.comments.map((item) => {
        if (item.id === replyId) {
          return { ...item, likeCount, hasLiked: !hasLiked };
        }
        return item;
      });

      this.setState({
        comments: replyParentId ? targetParentArr : targetArr,
      });
    });
  }

  /** *
   * 点击更多菜单
   * @param opt
   * @param val
   * @param [replyId]
   */
  onClickMenu({ opt, val }, replyId, replyParentId) {
    const { productId, postId, state } = this;

    // 对评论对操作
    const operaComment = () => {
      postApiV2PostsReplies({ productId, postId, replyId, opt, val }).then((resp) => {
        const res = getApiV3PostsRepliesReplies({
          productId,
          postId,
          commentId: replyParentId ? replyParentId : replyId,
        });
        const resData = state.comments.map((comment) => {
          if (comment.id !== replyId) {
            return comment;
          }
          return {
            ...comment,
            replies: unionBy(comment.replies, res.data, 'id'),
          };
        });
        this.setState({ comments: resData });
        this.showToast(`${val}${opt}`);
      });
    };

    const setGoodQuestionsaid = () => {
      const isGood = localStorage.getItem('isGood');

      if (!isGood) {
        localStorage.setItem('isGood', 'is_good');
        this.setState({
          show_modal_good_question: true,
        });
      }
    };

    // 对主帖的操作
    const operaPost = () => {
      postApiV2Posts({ productId, postId, opt, val, scene: 'post' }).then(async (resp) => {
        if (`${val}${opt}` === 'ongood') {
          setGoodQuestionsaid();
        }
        if (`${val}${opt}` === 'onhide') {
          resp.data.is_hidden = true;
        }
        const respData = await this.postListTagsAllGetApi(resp.data);
        this.showToast(`${val}${opt}`);
        this.setState({ post: respData });
      });
    };

    if (opt === 'report') {
      this.showModalReport(replyId);
      return;
    }

    if (!replyId) {
      operaPost();
    } else {
      operaComment();
    }
  }

  /**
   * 显示举报弹窗
   * @param [replyId] 如果是举报对象是评论，则带上评论id
   * */
  showModalReport(replyId) {
    this.setState({
      show_modal_report: true,
      report_reply_id: replyId,
    });
  }

  /**
   * 举报帖子或评论
   * */
  reportPosts(value) {
    const { state, productId, postId } = this;

    // 是否对评论进行操作
    const isReply = Boolean(state.report_reply_id);

    const clearState = () => {
      this.showToast('report');
      this.setState({ show_modal_report: false, report_reply_id: undefined });
    };

    const params = {
      productId,
      object_id: isReply ? state.report_reply_id : postId,
      comment: value,
      object_type: isReply ? 'reply' : 'post',
    };

    postApiAbuseReports(params)
      .then(clearState)
      .catch(err => message.error(err.message));
  }

  /**
   * 删除帖子
   */
  deletePost() {
    deleteApiPosts({ productId: this.productId, postId: this.postId })
      .then((resp) => {
        this.showToast('delete');
        // 删除成功后，后退到上一级页面，或者返回产品首页
        App.navigatorBack();
      })
      .catch(err => message.error(err.message));
  }

  /**
   * 删除评论
   * @param replyId
   * @param delReplyParentId
   */
  deleteComment(replyId, delReplyParentId) {
    const { state } = this;
    deleteApiPostsReplies({
      productId: this.productId,
      postId: this.postId,
      replyId,
    })
      .then((resp) => {
        this.showToast('delete');
        const delTargetParentArr = state.comments.map((obj) => {
          if (obj.id === delReplyParentId) {
            return {
              ...obj,
              replies: obj.replies.filter(item => item.id !== replyId),
              replyCount: obj.replies.length === obj.replies.length - 1 ? obj.replyCount : obj.replyCount - 1,
            };
          }
          return { ...obj };
        });
        const delTargetArr = state.comments.filter(item => item.id !== replyId);
        this.setState({
          comments: delReplyParentId ? delTargetParentArr : delTargetArr,
        });
      })
      .catch(err => message.error(err.message));
  }

  /**
   * 显示全局提示
   * @param text              提示类型，如果是错误，直接传入 Error 对象
   * @param DO_NOT_AUTO_HIDE  是否阻止自动隐藏
   */
  showToast(text, DO_NOT_AUTO_HIDE = false) {
    this.setState({ toast: text });

    if (!DO_NOT_AUTO_HIDE) {
      setTimeout(() => this.hideToast(), 1500);
    }
  }

  /**
   * 隐藏全局提示
   * */
  hideToast() {
    this.setState({ toast: '' });
  }

  /**
   * 判断用户是否登录
   * @return {boolean}
   * */
  checkAndShowModalLogin() {
    if (!this.isLogin()) {
      // 如果用户未登录，拉起登录弹窗
      this.setState({ show_modal_login: true });
      return false;
    }

    return true;
  }

  /**
   * 判断用户是否登录
   * */
  isLogin() {
    const { state } = this;
    return Boolean(state.user.user_id);
  }

  onUploadImage(data, onProgress) {
    return postApiPostsUploadImages({
      productId: this.productId,
      data,
      onProgress,
    });
  }

  onTippedSuccess() {
    const { productId, postId } = this;

    this.setState({ show_modal_tipped: false });

    this.showToast('tippedSuccess');

    // 更新已打赏的用户信息
    postApiTipBatch({ productId, postId }).then((res) => {
      const tipBatch = res.data[postId].data;
      this.setState({ tip_batch: tipBatch });
    });
  }

  toggleDisabledHidden() {
    this.setState((preState) => {
      const value = !preState.checked_disabled_hidden;
      localStorage.setItem('checked_disabled_hidden', JSON.stringify(value));
      return { checked_disabled_hidden: value };
    });
  }

  // 是否展示打赏弹窗，没登录的话展示登录弹窗
  handleShowModalTipped() {
    if (!this.isLogin()) {
      this.setState({ show_modal_login: true });
      return;
    }

    this.setState({ show_modal_tipped: true });
  }

  // 点击回复按钮
  handleShowTextarea(isShow, isDisabled, itemId) {
    if (!this.isLogin()) {
      this.setState({ show_modal_login: true });
      return;
    }

    if (!isShow && !isDisabled) {
      this.setState({ show_reply_textarea: itemId });
    } else {
      this.setState({ show_reply_textarea: false });
    }
  }

  // 没有登录态时点击更多按钮提示登录
  onCheckLogin() {
    if (!this.isLogin()) {
      this.setState({ show_modal_login: true });
      return false;
    }

    return true;
  }

  getTapdEntryStatus() {
    // 腾讯内部产品 & 自己是管理员，展示tapd绑定入口
    return this.state.productData.is_tencent_product && this.state.user.is_admin;
  }

  showTapdConfirm() {
    if (hasTapdPlatform(this.authorizedInfo.platforms)) {
      this.setState({ showTapdConfirm: true });
    } else {
      // 未绑定弹窗
      ConfirmModal.confirm({
        title: '关联 TAPD 平台',
        content: '尚未关联TAPD，是否立即前往关联',
        width: 640,
        onConfirm: () => {
          window.open(`/dashboard/products/${this.productId}?tab=2`);
          ConfirmModal.confirm({
            width: 640,
            title: '提示',
            content: '是否完成关联授权?',
            confirmText: '已完成授权',
            cancelText: '失败重试',
            onConfirm: () => window.location.reload(),
          });
        },
      });
    }
  }

  closeTapdConfirm() {
    this.setState({
      showTapdConfirm: false,
    });
  }

  // 获取关联的平台应用，用以获取关联事项列表
  getPlatformData() {
    return apiGetAuthorizedApp(this.productId)
      .then((res) => {
        let apps = {};
        res.data.platforms.forEach((p, pIndex) => {
          apps = { ...apps, [p.id]: [] };
          res.data.apps.forEach((a, aIndex) => {
            if (p.id === a.platform_id) apps[p.id].push(a);
          });
        });

        this.authorizedInfo = { platforms: res.data.platforms, apps };
      })
      .catch(() => {
        this.authorizedInfo = { platforms: [], apps: {} };
      });
  }

  // 在每个授权的平台应用里请求关联的事项列表
  async handleGetAuthMatters() {
    if (!this.getTapdEntryStatus()) {
      return;
    }

    const apps = flattenDeep(Object.values(this.authorizedInfo.apps));

    const getMattersPromise = apps.map((app) => {
      const platformInfo = this.authorizedInfo.platforms.find(p => p.id === app.platform_id);
      return postApiAuthorizeCallback({
        productId: this.productId,
        txc_app_id: app.txc_app_id,
        txc_post_id: this.postId,
        event: 'app_item_list_show',
      })
        .then((res) => {
          const { items } = res.data;
          return items.filter(Boolean).map((item) => {
            const transferItem = {
              ...item,
              txc_app_id: app.txc_app_id,
              platform_logo: platformInfo.logo,
              platform_id: platformInfo.id,
            };

            // 如果是已删除(或其它非正常情况, 目前无法区分)的tapd单
            if (item.platform_id === PLATFORM_ID.TAPD && !item.title && !item.status) {
              transferItem.isBad = true;
              transferItem.title = '工单状态异常，请前往TAPD查看或解除关联';
              transferItem.status = '数据异常';
            }

            return transferItem;
          });
        })
        .catch((err) => {
          const data = { ...err.response };
          data.txc_app_id = app.txc_app_id;
          data.platform_logo = platformInfo.logo;

          return data;
        });
    });

    Promise.all(getMattersPromise)
      .then(formatters => formatters.reduce((items, curr) => {
        // 将失败的项添加到列表展示中
        if (!curr.status && curr.data && curr.data.items.length > 0) {
          return [...items, ...curr.data.items];
        }

        if (Array.isArray(curr)) {
          return [...items, ...curr];
        }

        return [...items, curr];
      }, []))
      .then((authMatters) => {
        this.setState({ authMatters });
      })
      .catch((error) => {
        console.log('error:', error);
      });
  }

  // 取消关联接入方应用的事项
  handleDeleteMatter(item) {
    const { state } = this;
    const matter = state.authMatters.find(i => i.id && i.id === item.id);

    if (!matter) {
      message.error('mattersResp 找不到要删除的事项 id');
      return;
    }

    const params = {
      productId: this.productId,
      event: 'app_item_unbind',
      txc_app_id: matter.txc_app_id,
      txc_post_id: state.post.id,
      item_id: item.id,
    };

    if (item.type) {
      params.item_type = item.type;
    }

    postApiAuthorizeCallback(params).then((res) => {
      message.success('事项解除关联成功');
      let newAuthMatters = [...state.authMatters];
      newAuthMatters = newAuthMatters.filter(i => i.id !== item.id);
      this.setState({ authMatters: newAuthMatters });
    });
  }

  changePostsList(e) {
    const postData = Object.assign({}, this.state.post, { tags: e });
    this.setState({ post: postData });
  }

  // 加载更多子评论
  async handleReplyCount(replyId) {
    const { comments, countPagination } = this.state;
    const nextPageId = countPagination.has_more_pages ? countPagination.next_page_id : replyId;
    const res = await getApiV3PostsRepliesReplies({
      nextPageId,
      productId: this.productId,
      postId: this.postId,
      perPage: 10,
      commentId: replyId,
    });
    this.setState({ countPagination: res.pagination });
    const resData = comments.map((comment) => {
      if (comment.id !== replyId) {
        return comment;
      }
      return {
        ...comment,
        replies: unionBy(comment.replies, res.data, 'id'),
      };
    });
    this.setState({ comments: resData });
  }

  render() {
    const { state, props } = this;
    const showTapdEntry = this.getTapdEntryStatus();

    return (
      <Daemon onErrorRenderPortal={error => <ErrorToast>{error.message}</ErrorToast>}>
        {catchError => (
          <BasePagePc
            isShowLogin={state.show_modal_login}
            onLoginClose={() => this.setState({ show_modal_login: false })}
            getAccountInfo={res => this.setState({ accountInfo: res.data })}
            getUserInfo={res => this.setState({ user: res.data })}
            getProductInfo={res => this.setState({ productData: res.data })}
            getFaqs={true}
            getTeamblog={true}
          >
            <div className="layerContainer">
              <BreadCrumbs content={['反馈详情']} />

              <div className="postPage">
                <ErrorBoundary error_type={state.error_info}>
                  <div className="clearfix">
                    <div className="postPage_module">
                      <div className="feed_content">
                        <FeedDetail
                          {...state.post}
                          isAdmin={state.user.is_admin}
                          showTopic={state.showTopic}
                          tip_batch={state.tip_batch}
                          liked_list={state.liked_list}
                          can_be_tipped={state.can_be_tipped}
                          canReward={state.productData.tip_status}
                          productId={state.productData.id}
                          user={state.user}
                          onClickLike={event => this.likePost(event)}
                          onClickMenu={res => this.onClickMenu(res)}
                          onClickDelete={event => this.deletePost()}
                          onClickTipped={event => this.handleShowModalTipped()}
                          onCheckLogin={() => this.onCheckLogin()}
                          onClickTapd={() => this.showTapdConfirm()}
                          authMatters={state.authMatters}
                          mattersLoading={state.mattersLoading}
                          onMattersDelete={item => this.handleDeleteMatter(item)}
                          tapdEntry={showTapdEntry}
                          tagArrList={state.tagArrList}
                          changePostsList={this.changePostsList.bind(this)}
                          renderLink={this.state.productData.ugc_url_status}
                        />

                        <div className="feed_main">
                          <div className="feed_main_comment">
                            <h3 className="comment_title">评论 {state.pagination.total}</h3>
                            <div className="comment_content">
                              <a
                                href={hrefProfile(state.user.user_id)}
                                target="_blank"
                                className="comment__avatar-wrap"
                                rel="noreferrer"
                              >
                                <Avatar className="comment__avatar" src={state.user.avatar} />
                              </a>
                              <UserEnter
                                is_admin={state.user.is_admin}
                                onSubmit={this.onSubmit}
                                placeholder={'别害羞，快来说两句吧…'}
                                textbtn="评论"
                                onUploadImage={this.onUploadImage}
                                onError={(error) => {
                                  catchError(error);
                                }}
                                disabled={Boolean(state.post.is_locked)}
                                External={
                                  state.user.is_admin && state.post.is_hidden ? (
                                    <div className="text_func_public">
                                      <BtnPublic
                                        checked={state.checked_disabled_hidden}
                                        onChange={this.toggleDisabledHidden}
                                      />
                                    </div>
                                  ) : null
                                }
                              />

                              {!this.isLogin() && (
                                <div
                                  className="cover"
                                  onClick={event => this.checkAndShowModalLogin()}
                                  onKeyPress={event => this.checkAndShowModalLogin()}
                                  role="button"
                                  tabIndex="0"
                                />
                              )}
                            </div>

                            <PostCommentList
                              user={state.user}
                              post={state.post}
                              comments={state.comments}
                              pagination={state.pagination}
                              likedList={state.liked_list}
                              postId={this.postId}
                              productId={this.productId}
                              authorId={state.post.user_id}
                              catchError={catchError}
                              checkedDisabledHidden={state.checked_disabled_hidden}
                              showReplyTextarea={state.show_reply_textarea}
                              likeReply={(id, hasLiked, replyParentId) => this.likeReply(id, hasLiked, replyParentId)}
                              onCheckLogin={() => this.onCheckLogin()}
                              onClickMenu={(res, id, replyParentId) => this.onClickMenu(res, id, replyParentId)}
                              onClickDelete={(id, delReplyParentId) => this.deleteComment(id, delReplyParentId)}
                              handleShowTextarea={this.handleShowTextarea}
                              toggleDisabledHidden={() => this.toggleDisabledHidden()}
                              onUploadImage={this.onUploadImage}
                              onSubmit={(value, images, id) => this.onSubmit(value, images, id)}
                              handleReplyCount={(replyId) => {
                                this.handleReplyCount(replyId);
                              }}
                              renderLink={this.state.productData.ugc_url_status}
                            />

                            {state.pagination.has_more_pages && <LoadMore onShow={() => this.getComments()} />}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="postPage_side">
                      <AuthorMessage {...state.author_info} user_id={state.post.user_id} />

                      <PoweredBy
                        cid={state.accountInfo.cid}
                        txEnterpriseUrl={`/enterprise/${state.accountInfo.cid}/home`}
                      />
                    </div>
                  </div>
                  <StickyTool />
                </ErrorBoundary>
              </div>

              {state.show_modal_report && (
                <Modal onClose={event => this.setState({ show_modal_report: false })}>
                  <ModalReport onReport={value => this.reportPosts(value)} />
                </Modal>
              )}

              {state.show_modal_tipped && (
                <DesktopTippedModal
                  postId={this.postId}
                  productId={this.productId}
                  avatar={state.post.avatar_url}
                  nickName={state.post.nick_name}
                  onSuccess={this.onTippedSuccess}
                  onClose={event => this.setState({ show_modal_tipped: false })}
                  onError={(error) => {
                    catchError(error);
                  }}
                />
              )}

              {state.show_modal_good_question && (
                <Modal onClose={event => this.setState({ show_modal_good_question: false })}>
                  <GoodQuestionPopup
                    onClose={event => this.setState({ show_modal_good_question: false })}
                    isPc={true}
                  />
                </Modal>
              )}

              <GlobalToast type={state.toast} />

              {state.user && <OaPopUps avatar={state.user.avatar} />}
            </div>

            {// 评论成功后的微信弹窗
              state.showSuccessPostPopup && (
                <PostPopupWxSubscripe
                  avatar={state.user.avatar}
                  url={state.successPostUrl}
                  status={state.successPostStatus}
                  methodPc={state.methodPc}
                  onRetry={this.handlepostPopupSuccessRetry}
                  onClose={() => this.onCloseWxPopup()}
                />
              )}
            {showTapdEntry && (
              <TapdConfirm
                user={state.user}
                productId={this.productId}
                post={state.post}
                open={state.showTapdConfirm}
                onClose={() => this.closeTapdConfirm()}
                onSuccess={() => {
                  this.handleGetAuthMatters();
                  this.closeTapdConfirm();
                }}
              />
            )}
          </BasePagePc>
        )}
      </Daemon>
    );
  }
}

const ErrorToast = props => (
  <div className="toast toast-error">
    <div>{props.children.split(/\\n|\n|<br\/>|<br \/>/).join('，')}</div>
  </div>
);

ErrorToast.propTypes = {
  children: PropTypes.string,
};

export { App };
