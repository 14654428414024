import React from 'react';
import { href_profile } from 'components/href-helper';

export const AuthorMessage = (props) => (
    <div className="author_message">
        <h3 className="title">关于作者</h3>
        <div className="message">
            <div className="item">
                <a className="author_link" href={href_profile(props.user_id)}>
                    <span>{props.like_total}</span>
                    <span>被点赞</span>
                </a>
            </div>
            <div className="item">
                <a className="author_link" href={href_profile(props.user_id, true)}>
                    <span>{props.reply_total}</span>
                    <span>回复</span>
                </a>
            </div>
            <div className="item">
                <a className="author_link" href={href_profile(props.user_id)}>
                    <span>{props.post_total}</span>
                    <span>反馈</span>
                </a>
            </div>
        </div>
    </div>
);

AuthorMessage.defaultProps = {
    like_total: '-',
    reply_total: '-',
    post_total: '-',
    user_id: null
};
